const state = {
    cricFightPlayersList: null,
};
const getters = {
    cricFightPlayersList: state => state?.cricFightPlayersList,
};
const actions = {
    setCricFightPlayersList({ commit }, cricFightPlayersList){
        commit('setCricFightPlayersList', cricFightPlayersList)
    },
};
const mutations = {
    setCricFightPlayersList(state, cricFightPlayersList){
        state.cricFightPlayersList = cricFightPlayersList
    },
};
export default {
    state,
    getters,
    actions,
    mutations
};