import api from './api';
import { routes } from './apiRoutes';
import { headers } from '@/shared/services/headers';
import { errorToast } from './helper';

/**
 * Get the API route based on the route name and type.
 * @param {string} routeName - The name of the route.
 * @param {string} apiType - The type of the API ('user', 'sports', 'fantasy').
 * @returns {string} - The full API route.
 */
const getApiRoute = (routeName, apiType) => {
    const route = routes[apiType] && routes[apiType][routeName];

    if (!route) {
        throw new Error(`Route not found for type: ${apiType} and name: ${routeName}`);
    }
    return route;
};

/**
 * Make an API call using the specified parameters.
 * @param {string} method - The HTTP method (e.g., 'get', 'post').
 * @param {string} routeName - The name of the route.
 * @param {object} [data=null] - The data to be sent with the request.
 * @param {string} [apiType='user'] - The type of the API ('user', 'sports', 'fantasy').
 * @param {object} [params=null] - The URL parameters to be sent with the request.
 * @returns {Promise} - The Axios promise.
 */
export const apiCall = (method = 'get', routeName, data = null, apiType = 'user', params = null) => {
    if (!routeName) {
        throw new Error('routeName is required');
    }

    let url = getApiRoute(routeName, apiType);
    if(apiType == 'fantasy') {
        if(method == 'get') {
            url = 'api/fantasycricket' + url;
        } else if(method == 'post') {
            url = 'api/fantasy' + url;
            apiType = 'user';
        }
    }

    if(params) {
        Object.keys(params).forEach(key => {
            if(url.includes(`:${key}`)) {
                url = url.replace(`:${key}`, params[key]);
                delete params[key]
            }
        });
    }

    if (data instanceof FormData) {
        delete headers['Content-Type']; // Allow browser to set Content-Type for FormData
    } else {
        headers['Content-Type'] = 'application/json'; 
    }

    let ignoreClickHeaders = ['GET_SITE_SETTINGS','GET_SITE_VERSION'];

    let apiObj = {
        method,
        url,
        data,
        params
    }

    if(apiType != 'sports' && !ignoreClickHeaders.includes(routeName)) {
        apiObj.headers = headers;
    }
    return api[apiType](apiObj);
    
};