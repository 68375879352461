const CricketFightModule = () => import(/* webpackChunkName: "cricket-fight-module" */ './views/Module.vue');
const CricketFight = () => import(/* webpackChunkName: "cricket-fight" */ './views/CricketFight.vue');
const MyContests = () => import(/* webpackChunkName: "my-contest" */ './views/MyContests.vue');
const MatchDetails = () => import(/* webpackChunkName: "my-market" */ './views/MatchDetails.vue');
const MyTeams = () => import(/* webpackChunkName: "my-team" */ './views/MyTeams.vue');

const CricketFightRoutes = {
    path: '/cricket-fight',
    component: CricketFightModule,
    children: [
        {
            path: '',
            name: 'CricketFight',
            component: CricketFight
        },
        {
            path: 'my-contests',
            name: 'MyContests',
            component: MyContests
        },
        {
            path: ':match_id/:id?/:user_team?',
            name: 'MatchDetails',
            component: MatchDetails
        },
        {
            path: 'my-teams/:id',
            name: 'MyTeams',
            component: MyTeams
        }
    ],
}

export default CricketFightRoutes;