import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(customParseFormat);

const dateTime = {
    sportItemDate: (date, format = 'D MMM, h:mm A', timeZone = 'Asia/Kolkata') => {
        return dayjs.utc(date).tz(timeZone).format(format);
    },
    NotInplaySportItemDateOnly(date, format = 'DD MMM') {
        if(dayjs(date).isSame(dayjs(), 'day')) return 'Today'
        else return this.sportItemDate(date, format)
    },
    convertUnixDate: (date, format = 'DD MMM YYYY HH:mm:ss') => {
        return dayjs.unix(date).format(format)
    },
    currentTimestamp: () => {
        return dayjs().unix();
    },
    convertToTimestamp(date) {
        return dayjs(date).unix();
    },
    convertDateToTimstamp(date) {
        return dayjs(date).unix();
    },
    getcurrentDate: (date, format = 'YYYY-MM-DD') => {
        return dayjs(date).format(format);
    },
    getDateSaperatedByDays: (days, date = new Date(), format = 'YYYY-MM-DD' ) => {
        return dayjs(date).subtract(days, 'day').format(format);
    },
    getDateAddedByDays: (days, date = new Date(), format = 'YYYY-MM-DD' ) => {
        return dayjs(date).add(days, 'day').format(format);
    },
    convertOnlyTime: (time, format = "hh:mm A") =>{
        return dayjs(time, "HH:mm").format(format);
    },
    convertUtcDate: (date) => {
        return dayjs.utc(date);
    },
    // Wallet
    getUTCTime: (format = 'HH:mm:ss') =>{
        return dayjs.utc().format(format)
    },
    getPreviousDate: (day = 1, format = 'YYYY-MM-DD') => {
        return dayjs().subtract(day, 'day').format(format);
    },
    getcurrentTime: (format = 'HH:mm:ss') => {
        return dayjs().format(format);
    },
    getLocalDate:(date, format = 'DD MMM hh:mm A') => {
        return dayjs.utc(date).local().format(format);
    },
    // Matka
    isCurrentDateInRange: (startDate, endDate) => {
        const today = dayjs().startOf('day');
        const tomorrow = today.add(1, 'day');
    
        const start = dayjs(startDate).startOf('day');
        const end = dayjs(endDate).startOf('day');
    
        if (start.isSame(today) && end.isSame(today)) {
            return 'Today';
        } else if (start.isSame(tomorrow) && end.isSame(tomorrow)) {
            return 'Tomorrow';
        } else if (start.isBefore(today) || end.isBefore(today)) {
            return start.format('DD-MM-YYYY');
        }
    }
} 

export default dateTime